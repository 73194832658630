'use client';

import Link from 'next/link';
import { useParams } from 'next/navigation';
import Cta from 'shared/components/CtaBtn';
import { getI18nUtils } from '../i18n/i18n-utils';

const CustomErrorPage = ({ dictionary }) => {
  const { locale } = useParams();
  const { formatMessage } = getI18nUtils(dictionary);

  return (
    <div className="container">
      <div className="flex-container">
        <div>
          <h1 className="error-page-title ms-2">{formatMessage({ id: 'internal-server-error' })}</h1>
          <div className="error-page-links">
            <Link href={`/${locale}/jobs/`}>
              <Cta className="cta error-cta red" type="button">
                {formatMessage({ id: 'error-page-joboffer' })}
              </Cta>
            </Link>
            {process.env.NEXT_PUBLIC_APP !== 'werkenbijsynergie.be' && process.env.NEXT_PUBLIC_APP !== 'sandyou.be' && (
              <Link href={`/${locale}/agencies`}>
                <Cta className="cta error-cta red" type="button">
                  {formatMessage({ id: 'error-page-agency' })}
                </Cta>
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomErrorPage;
